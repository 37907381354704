// The defaults are from our testing account
export const STRIPE_PRODUCT_ID_DIY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID_DIY || '';
export const STRIPE_PRICE_MONTHLY_DIY = process.env.NEXT_PUBLIC_STRIPE_PRICE_MONTHLY_DIY || '';
export const STRIPE_PRICE_QUARTERLY_DIY = process.env.NEXT_PUBLIC_STRIPE_PRICE_QUARTERLY_DIY || '';
export const STRIPE_PRICE_YEARLY_DIY = process.env.NEXT_PUBLIC_STRIPE_PRICE_YEARLY_DIY || '';

export const STRIPE_PRODUCT_ID_DIY_MAX = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID_DIY_MAX || '';
export const STRIPE_PRICE_MONTHLY_DIY_MAX = process.env.NEXT_PUBLIC_STRIPE_PRICE_MONTHLY_DIY_MAX || '';
export const STRIPE_PRICE_QUARTERLY_DIY_MAX = process.env.NEXT_PUBLIC_STRIPE_PRICE_QUARTERLY_DIY_MAX || '';
export const STRIPE_PRICE_YEARLY_DIY_MAX = process.env.NEXT_PUBLIC_STRIPE_PRICE_YEARLY_DIY_MAX || '';

export const STRIPE_PRODUCT_ID_VIP = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID_VIP || '';
export const DEFAULT_VIP_PROFILES_LIMIT = '10000';
export const DEFAULT_VIP_SEARCHES_LIMIT = '800';
export const DEFAULT_VIP_AI_EMAILS_LIMIT = '1000';

export const STRIPE_PRODUCT_ID_DISCOVERY = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_DISCOVERY || '';
export const STRIPE_PRODUCT_ID_OUTREACH = process.env.NEXT_PUBLIC_STRIPE_PRODUCT_OUTREACH || '';

export const STRIPE_PRICE_MONTHLY_DISCOVERY = process.env.NEXT_PUBLIC_STRIPE_PRICE_MONTHLY_DISCOVERY || '';
export const STRIPE_PRICE_MONTHLY_OUTREACH = process.env.NEXT_PUBLIC_STRIPE_PRICE_MONTHLY_OUTREACH || '';

export const STRIPE_PRICE_MONTHLY_DISCOVERY_USD = process.env.NEXT_PUBLIC_STRIPE_PRICE_MONTHLY_DISCOVERY_USD || '';
export const STRIPE_PRICE_MONTHLY_OUTREACH_USD = process.env.NEXT_PUBLIC_STRIPE_PRICE_MONTHLY_OUTREACH_USD || '';

if (
    !STRIPE_PRODUCT_ID_VIP ||
    !STRIPE_PRODUCT_ID_DISCOVERY ||
    !STRIPE_PRODUCT_ID_OUTREACH ||
    !STRIPE_PRICE_MONTHLY_DISCOVERY ||
    !STRIPE_PRICE_MONTHLY_OUTREACH ||
    !STRIPE_PRICE_MONTHLY_DISCOVERY_USD ||
    !STRIPE_PRICE_MONTHLY_OUTREACH_USD
) {
    throw new Error('Stripe price ids are not set');
}

export const DISCOVERY_PLAN_SEARCHES = process.env.DISCOVERY_PLAN_SEARCHES || '900';
export const DISCOVERY_PLAN_PROFILES = process.env.DISCOVERY_PLAN_PROFILES || '200';
export const DISCOVERY_PLAN_AI_EMAILS = process.env.DISCOVERY_PLAN_AI_EMAILS || '0';
export const DISCOVERY_PLAN_TRIAL_DAYS = process.env.DISCOVERY_PLAN_TRIAL_DAYS || '14';
export const DISCOVERY_PLAN_TRIAL_PROFILES = process.env.DISCOVERY_PLAN_TRIAL_PROFILES || '50';
export const DISCOVERY_PLAN_TRIAL_SEARCHES = process.env.DISCOVERY_PLAN_TRIAL_SEARCHES || '200';
export const DISCOVERY_PLAN_TRIAL_AI_EMAILS = process.env.DISCOVERY_PLAN_TRIAL_AI_EMAILS || '0';

export const DISCOVERY_PLAN = {
    planId: STRIPE_PRODUCT_ID_DISCOVERY,
    priceId: STRIPE_PRICE_MONTHLY_DISCOVERY,
    searches: DISCOVERY_PLAN_SEARCHES,
    profiles: DISCOVERY_PLAN_PROFILES,
    ai_emails: DISCOVERY_PLAN_AI_EMAILS,
    trial_days: DISCOVERY_PLAN_TRIAL_DAYS,
    trial_profiles: DISCOVERY_PLAN_TRIAL_PROFILES,
    trial_searches: DISCOVERY_PLAN_TRIAL_SEARCHES,
    trial_ai_emails: DISCOVERY_PLAN_TRIAL_AI_EMAILS,
};
